import React from "react";
import { Link } from "react-router-dom";
import ISO9001dqs from "../../assets/new/ISO 9001 DQS Logo for Bismuth Engineering.jpg"
import ISO9001iqn from "../../assets/new/ISO 9001 IQNet-Logo for Bismuth Engineering .jpg"
import ISO14001dqs from "../../assets/new/ISO 14001 DQS Logo for Bismuth Engineering.jpg"
import ISO145001ohs from "../../assets/new/ISO 45001-OHSM Badge.jpg"
import p1 from "../../assets/new/p1.jpg"
import p31 from "../../assets/new/p31.jpg"
import p36 from "../../assets/new/p36.jpg"

import ISO9001dqsPdf from "../../assets/new/ISO 9001 Certificate for Bismuth Engineering.pdf"
// import ISO9001iqn from "../../assets/new/ISO 9001 IQNet-Logo for Bismuth Engineering .jpg"
import ISO14001dqsPdf from "../../assets/new/ISO 14001 Certificate for Bismuth Engineering.pdf"
import ISO145001ohsPdf from "../../assets/new/ISO 45001 Certificate for Bismuth Engineering.pdf"

const TopAbout = () => {
  return (
    <>
      {/* <!-- about area start --> */}
      <div className="tp-about-area pt-140">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-lg-6">
              <div className="tp-about-left pr-90">
                <div className="tp-section-wrapper pt-5">
                  <span className="tp-section-subtitle">
                    we increase your business
                  </span>
                  <h2 className="tp-section-title pt-25 mb-30">
                    The right hands for your project
                  </h2>
                  <p style={{textAlign: "justify"}}>
                    Bismuth Engineering Limited is the unrivaled choice for Engineering,
                    Tech/ICT, Chemical Manufacturing, Procurement, and industrial services
                    due to out experienced professionals, diversified expertise, innovative
                    technology integration, commitment to quality and safety, client-centric
                    approach, global reach, eco-friendly practices, cost-effectiveness, strong
                    industry reputation, and continuous improvement efforts.

                  </p>
                  <p className="pt-10">
                    Our track record, excellence, and versatility make them the premier company
                    for any industrial project.
                  </p>
                </div>
                <div className="mt-45 mb-30">
                  <Link to="/about">
                    <div className="tp-theme-btn d-flex align-items-center">
                      <div className="tp-btn-inner">
                        <p className="mb-0">Let’t Talk</p>
                        <b>Get in Touch</b>
                        <span className="d-inline-block">
                          <svg
                            width="46"
                            height="8"
                            viewBox="0 0 46 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M45.3536 4.35355C45.5488 4.15829 45.5488 3.84171 45.3536 3.64645L42.1716 0.464466C41.9763 0.269204 41.6597 0.269204 41.4645 0.464466C41.2692 0.659728 41.2692 0.976311 41.4645 1.17157L44.2929 4L41.4645 6.82843C41.2692 7.02369 41.2692 7.34027 41.4645 7.53553C41.6597 7.7308 41.9763 7.7308 42.1716 7.53553L45.3536 4.35355ZM0 4.5H45V3.5H0V4.5Z"
                              fill="currentColor"
                            />
                          </svg>
                          <svg
                            width="46"
                            height="8"
                            viewBox="0 0 46 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M45.3536 4.35355C45.5488 4.15829 45.5488 3.84171 45.3536 3.64645L42.1716 0.464466C41.9763 0.269204 41.6597 0.269204 41.4645 0.464466C41.2692 0.659728 41.2692 0.976311 41.4645 1.17157L44.2929 4L41.4645 6.82843C41.2692 7.02369 41.2692 7.34027 41.4645 7.53553C41.6597 7.7308 41.9763 7.7308 42.1716 7.53553L45.3536 4.35355ZM0 4.5H45V3.5H0V4.5Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-right p-relative">
                <div className="about-img text-md-end">
                  {/*<img src="assets/img/about/01.jpg" alt="" />*/}
                  <img src={p1} alt="" style={{maxHeight: 650}} />
                </div>
                <div className="tp-about-info text-center grey-bg-4">
                  <h4 className='text-black' style={{fontWeight: 900}}>85-176-9793</h4>
                  <h4>
                    Duns <br />
                    Number
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4 mt-4">
            <h1 className="mb-4">Certifications</h1>
            <div className="col text-center">
              <img src={ISO9001dqs} alt="iso9001dqs"/>
              <a href={ISO9001dqsPdf} download className="mt-4">Download</a>
            </div>
            <div className="col text-center">
              <img src={ISO9001iqn} alt="iso9001iqn"/>
              <a href={ISO9001dqsPdf} download className="mt-4">Download</a>
            </div>
            <div className="col text-center">
              <img src={ISO14001dqs} alt="iso14001dqs"/>
              <a href={ISO14001dqsPdf} download className="mt-4">Download</a>
            </div>
            <div className="col text-center">
              <img src={ISO145001ohs} alt="iso45001ohs"/>
              <a href={ISO145001ohsPdf} download className="mt-4">Download</a>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- about about end--> */}
    </>
  );
};

export default TopAbout;
