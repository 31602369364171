import React from "react";

const Footer = () => {
  return (
    <>
      {/* <!-- footer area start --> */}
      <footer>
        <div className="tp-footer__area black-bg">
          <div className="tp-footer">
            {/* <!-- main-footer start  --> */}
            <div className="tp-footer__main">
              <div className="container">
                <div className="tp-footer-border pt-60 pb-30">
                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">
                          Our Location
                        </h3>
                        <ul>
                          <li>
                            <a href="#">Lagos</a>
                          </li>
                          <li>
                            <a href="#">Port Harcourt</a>
                          </li>
                          <li>
                            <a href="#">Abuja</a>
                          </li>
                          <li>
                            <a href="#">United Kingdon</a>
                          </li>
                          <li>
                            <a href="#">United States</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget pb-30">
                        <h3 className="tp-footer__widget-title">Our Service</h3>
                        <ul>
                          <li>
                            <a href="#">Manufacturing of Chemicals</a>
                          </li>
                          <li>
                            <a href="#">Engineering Services</a>
                          </li>
                          <li>
                            <a href="#">ICT Consultancy</a>
                          </li>
                          <li>
                            <a href="#">Chemical Supplies</a>
                          </li>
                          <li>
                            <a href="#">Fabrication</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 ">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">Quick Links</h3>
                        <ul>
                          <li>
                            <a href="#">Our Team</a>
                          </li>
                          <li>
                            <a href="#">Faq</a>
                          </li>
                          <li>
                            <a href="#">Portfolio</a>
                          </li>
                          <li>
                            <a href="#">Service</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">Quick Links</h3>
                        <ul>
                          <li>
                            <a href="#">
                              Suite 11, Emmanuel Plaza, along G. U. Ake Road, Eliozu, Port Harcourt.
                            </a>
                          </li>
                          <li>
                            <a href="tel:+2348159332046">(+234)8159332046, (+234)8092355297</a>
                          </li>
                          <li>
                            <a href="mailto:info@bismuthengineering.com">
                              info@bismuthengineering.com
                            </a>
                          </li>
                          <li>
                            <span> Office Hours: 9AM - 6PM</span>
                          </li>
                          <li>
                            <span> Monday - Friday</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- main footer end  --> */}
          </div>
        </div>

        {/* <!-- footer copy right --> */}
        <div className="top-footer-copyright pt-30 black-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-12">
                <div className="tp-copyrigh-text mb-30">
                  <span>
                    © {new Date().getFullYear()} Bismuth Engineering. All Rights Reserved.
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="tp-footer-social-icon mb-30 text-md-end">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- footer copyright end  --> */}
      </footer>
      {/* <!-- footer area end --> */}
    </>
  );
};

export default Footer;
