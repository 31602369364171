import React from "react";
import { ServiceItem } from "../../HomeTwo/Services";

const OurService = () => {
  return (
    <>
      <div className="tp-service-ara grey-bg-4 pt-140 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center mb-70">
                <span className="tp-section-subtitle mb-30">OUR SERVICES</span>
                <h2 className="tp-section-title">Explore Our Services</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <ServiceItem
              icon="pe-7s-arc"
              title="General Engineering Services"
              desc="Projects given to us involve performance of engineering
              design, procurement of necessary materials, and completion of
              construction. These projects include inter alia construction,
              building and welding works. Using data analysis and industry
              benchmarks, we identify project level savings while utilising
              field level input, site visits and engagement with cross-functional
              project teams. Our company’s culture of discipline and rigor enable
              clients to rely on us to achieve deliverables within set time."
            />
            <ServiceItem
              icon="pe-7s-cloud-download"
              title="ICT Solutions"
              desc="Bismuth Engineering assists companies to harness new technology
              and leverage hardware and software as a source of innovation and competitive
              growth. We have a set of solutions that help companies in different sectors
              to enhance digital infrastructure covering the entire industrial chain,
              from exploration, production, storage and transportation to distribution itself.
              These solutions include optimisation of warehouse maintenance through predictive
              analysis, dynamic stock adjustments and accurate calibration of available assets."
            />
            <ServiceItem
              icon="pe-7s-disk"
              title="Business Support"
              desc="We provide commodity chemicals, wellbore cleanout services, hardware and software solutions and lease and fabricate industrial equipment for end users. These include inter alia:

              Industrial Chemicals: Glycols & Chlorides, Hydroxides & Oils, Acids & Alcohols, Aromatics & Ketones, Lubricants & Greases, Carbonates & Amines, Emulsifiers & Cleaning Agents.

              Industrial Equipment: Surface & Overhead Tanks, Pumps & Compressors, Pipelines & Welding Works, Trucks & Forklifts, Mobile & Crawler Cranes, Boats & Barges, Excavators & Swamp Buggies.

              Industrial Services: Engineered Chemistry, Calibration, Well Bore Clean Out, NDT & Wire Ropes, Tank Cleaning & Hydro Jetting, Warehousing & Storage, Engineering Procurement & Construction."
            />
            <ServiceItem
              icon="pe-7s-hammer"
              title="Manufacturing of Chemicals and Cosmetics"
              desc="Bismuth Engineering Limited is an energy service company registered with the Nigerian Upstream Petroleum Regulatory Commission (formerly DPR) in Nigeria with expertise in services including manufacturing of industrial chemicals and engineered chemistry. We focus on providing your business with services related to manufacturing of household and drilling chemicals and usage of these chemicals in solving engineering problems. We also manufacture cosmetics, paints and coolants at our locations in Kaduna and Lagos."
            />
            <ServiceItem
              icon="pe-7s-umbrella"
              title="Chemical Supplies"
              desc="In 2022, we signed partnership agreements with the world’s leading industrial and specialty chemicals distribution company based in Netherlands inclusive of product delivery and after sales services for the benefit of our clientele. Our ongoing working relationship with the top chemical manufacturing companies in North America and Europe gives us greater capacity to provide professional service and technological expertise to our customers for drilling, cementing, production, stimulation, completion, refining and pipeline cleaning solutions."
            />
            <ServiceItem
              icon="pe-7s-graph3"
              title="Maintenance"
              desc="With our team of well-trained engineers and technicians, we have the skill and required competence to carry out total rehabilitation, upgrade and maintenance services in pumps, compressors, sand blasting, spray painting, mechanical and electrical maintenance of portakabins, containers, baskets and tanks."
            />
            <ServiceItem
              icon="pe-7s-cup"
              title="Fabrication Services"
              desc="Working in tandem with our partners, we are in a position to fabricate, rent, lease or sell equipment and accessories such as surface storage and overhead tanks, chemical injection pumps, ISO tanks, mud tanks and pumps, air compressors, tote and onshore chemical tanks, excavators and swamp buggies."
            />
            <ServiceItem
              icon="pe-7s-config"
              title="Wellbore CLeaning and Related Services"
              desc="Bismuth Engineering wellbore clean-up mechanical tools and chemicals remove debris that interferes with normal operations without damaging the well structure. We offer reliable and field-proven solutions for effective wellbore clean-up and debris management to ensure successful completion operations and maximize reservoir returns."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OurService;
